import { Component } from 'react'
import axios from 'axios';
import Loading from './Loading'
import config from './config.js'
import Players from './Players'

class KidsTournaments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tournamentId: props.tournamentId.id,
            matches: [],
            classificationTeams: null,
            results: {
                visible: true
            },
            classification: {
                visible: false
            },
            teams: {
                visible: false
            },
            players: {
                visible: false
            },
            isLoading: true
        }
    }

    async componentDidMount() {
        let matches = await axios.post(`${config.path}getMatchesFromTournament`, { tournamentId: this.state.tournamentId }).then((res) => {
            return res.data.data
        })
        let classification = await axios.post(`${config.path}getClassifcationFromTournament`, { tournamentId: this.state.tournamentId }).then((res) => {
            return res.data.data
        })

        this.setState({ matches, isLoading: false, classificationTeams: classification })
    }
    // getClasification = async () => {
    //     this.setState({ isLoading: true })

    // }

    renderBootstrapColumns = (cols) => {
        return `col-lg-${cols} col-md-${cols} col-xs-${cols}`
    }
    renderBootstrapOffset = (cols) => {
        return `offset-lg-${cols} offset-md-${cols} offset-xs-${cols}`
    }

    changeBar = (tab) => {
        let obj = {
            'results': {
                visible: false
            },
            'classification': {
                visible: false
            },
            'teams': {
                visible: false
            },
            'players': {
                visible: false
            }
        }

        obj[tab] = {
            visible: true
        }

        this.setState({ results: obj['results'], classification: obj['classification'], teams: obj['teams'], players: obj['players'], })

    }

    printResults = () => {
        return this.state.matches.map((value) => {
            let result = value.result.split('-')
            if (Number(result[0]) > Number(result[1])) {
                return (
                    <tr>
                        <td className="win-color">{value.teamAName}</td>
                        <td className="result-a">{result[0]}</td>
                        <td className="img-ball-results"><img src="./ball.png"></img></td>
                        <td className="result-b">{result[1]}</td>
                        <td className="lost-color away-team">{value.teamBName}</td>
                    </tr>
                )
            } else if (Number(result[0]) === Number(result[1])) {
                return (<tr>
                    <td className="draw-color">{value.teamAName}</td>
                    <td className="result-a">{result[0]}</td>
                    <td className="img-ball-results"><img src="./ball.png"></img></td>
                    <td className="result-b">{result[1]}</td>
                    <td className="draw-color away-team">{value.teamBName}</td>
                </tr>)
            } else {
                return (<tr>
                    <td className="lost-color">{value.teamAName}</td>
                    <td className="result-a">{result[0]}</td>
                    <td className="img-ball-results"><img src="./ball.png"></img></td>
                    <td className="result-b">{result[1]}</td>
                    <td className="win-color away-team">{value.teamBName}</td>
                </tr>)
            }

        })
    }
    printClassification = () => {
        // await this.getClasification();
        console.log(`clas`)
        console.log(this.state.classificationTeams)
        return this.state.classificationTeams.map((value, index) => {
            let avgGoals = value.goalsEntered - value.goalsRecivied;
            let colorOFAvg = avgGoals >= 0 ? `win-color` : `lost-color`
            return (
                <tr>
                    <td className="position-class">{index + 1}</td>
                    <td className="team-name-class">{value.name}</td>
                    <td className="neutral-color">{value.matchPlayed}</td>
                    <td className="neutral-color">{value.wins}</td>
                    <td className="neutral-color">{value.lost}</td>
                    <td className="neutral-color">{value.draw}</td>
                    <td className={colorOFAvg}>{avgGoals}</td>
                    <td className="draw-color">{value.points}</td>
                </tr>
            )

        })
    }
    printTeams = () => {
        // await this.getClasification();
        return this.state.classificationTeams.map((value, index) => {
            return (
                <div className={this.renderBootstrapColumns(2) + ` teams-class`}><span>{value.name}</span></div>
            )
        })
    }

    render() {
        console.log(this.state.matches)
        if (!this.state.isLoading) {
            return (
                <div>
                    <div className="row name-tournaments">
                        <div className={this.renderBootstrapColumns(12)}><span>{this.props.tournamentId.name}</span></div>
                    </div>
                    <nav className="menu-tournaments">
                        <button onClick={this.props.hideTournament} class="btn button-arrow"><i class="arrow left"></i>Турнири</button>

                        <a onClick={() => { this.changeBar('results') }} className={`links-tournaments ${this.state.results.visible ? 'active' : ''}`}>Резултати</a>
                        <a onClick={() => { this.changeBar('classification') }} className={`links-tournaments ${this.state.classification.visible ? 'active' : ''}`}>Класация</a>
                        <a onClick={() => { this.changeBar('teams') }} className={`links-tournaments ${this.state.teams.visible ? 'active' : ''}`}>Отбори</a>
                        <a onClick={() => { this.changeBar('players') }} className={`links-tournaments ${this.state.players.visible ? 'active' : ''}`}>Team of the week</a>
                    </nav>
                    {/* RESULTS */}
                    {
                        this.state.results.visible && <div className="row tournaments-container">
                            <div className={this.renderBootstrapColumns(12)}><span>Резултати</span></div>
                            <div className={`${this.renderBootstrapOffset(3)} ${this.renderBootstrapColumns(6)}`}>
                                <div class="tbl-header">
                                    <table cellpadding="0" cellspacing="0" border="0">
                                        <thead>
                                            <tr>
                                                <th> </th>
                                                <th> </th>
                                                <th> </th>
                                                <th> </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="tbl-content">
                                    <table cellpadding="0" cellspacing="0" border="0">
                                        {this.printResults()}
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {/* CLASSIFICATION */}
                    {this.state.classification.visible && <div className="row tournaments-container">
                        <div className={this.renderBootstrapColumns(12)}><span>Класация</span></div>
                        <div className={`${this.renderBootstrapOffset(3)} ${this.renderBootstrapColumns(6)}`}>
                            <div class="tbl-header classification-header">
                                <table cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <th>pos</th>
                                        <th>Име</th>
                                        <th>М</th>
                                        <th>П</th>
                                        <th>З</th>
                                        <th>Р</th>
                                        <th>+/-</th>
                                        <th>Т</th>
                                    </tr>
                                    {this.printClassification()}
                                </table>
                            </div>
                            {/* <div class="tbl-content classification-content">
                                <table cellpadding="0" cellspacing="0" border="0">
                                    
                                </table>
                            </div> */}
                        </div>
                    </div>}
                    {/* TEAMS */}
                    {this.state.teams.visible && <div className="row tournaments-container">
                        <div className={this.renderBootstrapColumns(12)}><span>Отбори</span></div>
                        <div className={this.renderBootstrapColumns(12)}>
                            <div className="row container-teams">
                                {this.printTeams()}
                            </div>
                        </div>
                    </div>}
                    {/* PLAYERS */}
                    {this.state.players.visible && <div className="row tournaments-container">
                        <div className={this.renderBootstrapColumns(12)}><span>Team of the week</span></div>
                        <div></div>
                        <Players tournamentId={this.state.tournamentId} />
                    </div>}
                </div>

            )
        } else {
            return (<Loading></Loading>)
        }
    }
}

export default KidsTournaments