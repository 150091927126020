
let upload = {
    uploadImage: async function (image) {
        const files = image;
        const data = new FormData();
        data.append('file', files[0]);
        data.append('upload_preset', 'ml_default');
        const res = await fetch('https://api.cloudinary.com/v1_1/div4vo1g2/image/upload', {
            method: "POST",
            body: data
        })
        const file = await res.json()
        return file

    }
}

export default upload