import { Component } from 'react'

class Contacts extends Component {

    renderBootstrapColumns = (cols) => {
        return `col-lg-${cols} col-md-${cols} col-xs-${cols}`
    }
    renderBootstrapOffset = (cols) => {
        return `offset-lg-${cols} offset-md-${cols} offset-xs-${cols}`
    }

    render() {
        return (
            <div>
                <div className="row name-tournaments">
                    <div className={this.renderBootstrapColumns(12)}><span className='title-home' >Контакти</span></div>
                    <div className={this.renderBootstrapColumns(12) + ' conatcs-form'}>
                        <div className="row">
                            <div className={this.renderBootstrapColumns(12)}>
                                <div className="row">
                                    <div className={this.renderBootstrapColumns(4)}>
                                        <div className="row">
                                            <div className={this.renderBootstrapColumns(12)}>
                                                <span className="working-time-title">Работно време</span>
                                            </div>
                                            <div className={this.renderBootstrapColumns(12)}>
                                                <span className="working-time">08:00 - 23:00</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.renderBootstrapColumns(4)}>
                                        <div className="row">
                                            <div className={this.renderBootstrapColumns(12)}>
                                                <span className="working-time-title">Телефон</span>
                                            </div>
                                            <div className={this.renderBootstrapColumns(12)}>
                                                <span className="working-time"><a href="tel:0877129276">0877129276</a></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.renderBootstrapColumns(4)}>
                                        <div className="row">
                                            <div className={this.renderBootstrapColumns(12)}>
                                                <span className="working-time-title">Имейл</span>
                                            </div>
                                            <div className={this.renderBootstrapColumns(12)}>
                                                <span className="working-time"><a href="mailto:miridrujba@abv.bg">miridrujba@abv.bg</a></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.renderBootstrapColumns(12) + ' map-responsive'}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3489.3532203115174!2d23.35263529502528!3d42.65744304999647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa8425549f6aa5%3A0x77eda948e5b815da!2z0KHQv9C-0YDRgtC10L0g0LrQvtC80L_Qu9C10LrRgSDigJ7QnNC40YAg0Lgg0LTRgNGD0LbQsdCw4oCc!5e0!3m2!1sbg!2sbg!4v1612523034245!5m2!1sbg!2sbg" width="1000" height="450" frameborder="0" style={{ border: '0' }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contacts