import { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'mobile-device-detect';


class Home extends Component {
    constructor() {
        super()
    }
    render() {
        let showArrows = true;
        if (isMobile) {
            showArrows = false
        }

        return (
            <div>
                <h1 className='title-home' >МИР И ДРУЖБА</h1>
                <div className='carousel-home'>
                    <Carousel
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={true}
                        infiniteLoop={true}
                        showArrows={showArrows}
                    >
                        <div>
                            <img src="/img-2.jpg" alt="" className="img-home" />
                        </div>
                        <div>
                            <img src="/img-3.jpg" alt="" className="img-home" />
                        </div>
                        <div>
                            <img src="/img-4.jpg" alt="" className="img-home" />
                        </div>
                        <div>
                            <img src="/img-5.jpg" alt="" className="img-home" />
                        </div>
                        <div>
                            <img src="/img-6.jpg" alt="" className="img-home" />
                        </div>
                        <div>
                            <img src="/img-7.jpg" alt="" className="img-home" />
                        </div>
                    </Carousel>
                </div >
                <div>
                    <p className='desc-home'>
                        Спортен комплекс Мир и Дружба – Спорт за всички
                        Изграден като част от парковото пространство в Студентски град, Спортен комплекс Мир и дружба предлага условия за спорт за всеки.
                        Разполагаме с футболни терени за минифутбол формат 5+1, както и терен за футбол 7+1. През зимния сезон предлагаме футбол на закрито.
                        Друга важна част от нашата дейност е тенисът, като за клиентите ни са на разположение 5 броя тенис корта с настилка клей.
                        Приятната паркова среда и спокойствието, както и позитивното отношение на персонала спомагат за доброто настроение на нашите посетители!
                        ЗАПОВЯДАЙТЕ
                    </p>
                </div>
            </div>
        )
    }
}

export default Home;