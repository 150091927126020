import { React, Component } from 'react'
// import api from './common/api'
import Cookies from 'js-cookie';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import history from '../../history'
import Loading from '../Loading';
import config from '../config.js'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: null,
      isLoggedIn: false,
      isLoading: false
    }
  }
  async componentDidMount() {

  }

  changeUsername = () => {
    let val = document.getElementById('input_email').value;
    this.setState({ username: val })
  }
  changePassword = () => {
    let val = document.getElementById('input_password').value;
    this.setState({ password: val })
  }

  loggedIn = async () => {
    this.setState({ isLoading: true })
    let res = await axios.post(`${config.path}login`, {
      username: this.state.username,
      password: this.state.password
    })

    if (res.status == 200) {
      history.push('/home')
      Cookies.set('token', res.data.token)
      this.setState({ isLoggedIn: true, isLoading: false })
    } else {
      this.setState({ error: "The user name or password is incorrect. Try again.", isLoading: false })
    }
  }


  render() {

    if (!this.state.isLoading) {
      if (this.state.isLoggedIn) {
        return <Redirect to="/addData"></Redirect>
      }
      return (
        <form class="box">
          <h1>Login</h1>
          <input onChange={this.changeUsername} id="input_email" type="text" name="" placeholder="Username" />
          <input onChange={this.changePassword} id="input_password" type="password" name="" placeholder="Password" />
          <button onClick={() => { this.loggedIn() }} value="Login">Влез</button>
        </form>
      )
    } else {
      return (<Loading></Loading>)
    }
  }
}

export default Login
