import { React, Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

class Navbar extends Component {
    constructor() {
        super();
    }


    renderNavBar = () => {
        let url = document.URL.split('/');
        let tab = url[url.length - 1]

        return (
            <ul className="navbar-nav">
                <li className={`nav-item ${tab == 'home' ? 'active' : ""}`}>
                    <a className="nav-link" href="/home">Начало</a>
                </li>
                <li className={`nav-item ${tab == 'tournaments' ? 'active' : ""}`}>
                    <a className="nav-link" href="/tournaments">Турнири</a>
                </li>

                <li className={`nav-item ${tab == 'news' ? 'active' : ""}`}>
                    <a className="nav-link" href="/news">Новини</a>
                </li>
                <li className={`nav-item ${tab == 'contacts' ? 'active' : ""}`}>
                    <a className="nav-link" href="/contacts">Контакти</a>
                </li>
                <li className={`login nav-item ${tab == (Cookies.get('token') ? "addData" : "login") ? 'active' : ""}`}>
                    <a className="nav-link" href={Cookies.get('token') ? "/addData" : "/login"}>{Cookies.get('token') ? "Admin" : "Влез"}</a>
                </li>
            </ul>
        )
    }
    render() {
        return (

            <nav className="navbar navbar-dark navbar-expand-sm">
                <a className="navbar-brand" href="#">
                    <img src="/logo2.png" alt="logo" />
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-2" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </a>


                <div className="collapse navbar-collapse" id="navbar-list-2">
                    {this.renderNavBar()}
                </div>
            </nav>
        )
    }
}

export default Navbar