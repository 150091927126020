import { Component } from 'react'
import axios from 'axios';
import KidsTournaments from './KidsTournaments';
import Loading from './Loading';
import config from './config.js'

class Tournaments extends Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            tournaments: [],
            tournamentIdSelected: null
        }
    }

    async componentDidMount() {
        let tournaments = await axios.post(`${config.path}getAllTournaments`).then((res) => {
            console.log(res)
            return res.data.data
        })

        this.setState({ tournaments, isLoading: false })
    }

    renderBootstrapColumns = (cols) => {
        return `col-lg-${cols} col-md-${cols} col-xs-${cols}`
    }
    renderBootstrapOffset = (cols) => {
        return `offset-lg-${cols} offset-md-${cols} offset-xs-${cols}`
    }

    setTournamentId = (id) => {
        this.setState({ tournamentIdSelected: id })
    }

    printTeams = () => {
        // await this.getClasification();
        return this.state.tournaments.map((value, index) => {
            return (
                <div onClick={() => { this.setTournamentId(value) }} className={this.renderBootstrapColumns(2) + ` tournaments-class`}><span>{value.name}</span></div>
            )
        })
    }

    hideTournament = () => {
        console.log(`hide`)
        this.setState({ tournamentIdSelected: null })
    }

    render() {
        if (!this.state.isLoading) {
            if (this.state.tournamentIdSelected) {
                return (<KidsTournaments hideTournament={this.hideTournament} tournamentId={this.state.tournamentIdSelected}></KidsTournaments>)
            } else {
                return (
                    <div>
                        <div className="row name-tournaments">
                            <div className={this.renderBootstrapColumns(12)}><span className='title-home' >Турнири</span></div>
                            <div className={this.renderBootstrapColumns(12)}>
                                <div className="row">
                                    {this.printTeams()}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <Loading></Loading>
            )
        }
    }
    // render() {
    //     return (
    //         <div>
    //             <nav className="menu-tournaments">
    //                 <a href="/kidsTournaments" className="links-tournaments">Детски турнири</a>
    //                 <a href="#" className="links-tournaments">Юношески турнири</a>
    //                 <a href="#" className="links-tournaments">Мъже турнири</a>
    //                 <a href="#" className="links-tournaments">Общи турнири</a>
    //             </nav>
    //         </div>
    //     )
    // }
}

export default Tournaments