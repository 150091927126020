import { Component } from 'react'
import axios from 'axios';
import Loading from './Loading';
import config from './config.js'
import { Carousel } from 'react-responsive-carousel';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tournamentId: props.tournamentId,
            news: null,
            isLoading: true,
            src: []
        }
    }

    async componentDidMount() {
        let news = await axios.post(`${config.path}getNewsFromTournament`, { tournamentId: this.state.tournamentId }).then((res) => {
            console.log(res.data.data)
            return res.data.data
        })

        this.setState({ news, isLoading: false })

    }

    renderBootstrapColumns = (cols) => {
        return `col-lg-${cols} col-md-${cols} col-xs-${cols}`
    }
    renderBootstrapOffset = (cols) => {
        return `offset-lg-${cols} offset-md-${cols} offset-xs-${cols}`
    }
    openImage = (image) => {
        this.setState({ src: image })
    }
    printImagesFromNews = (images) => {
        return images.map((value) => {
            return (<div >
                <img src={value} alt="" className="img-home-2" />
            </div>)
        })
    }

    printNews = () => {
        let news = this.state.news

        return news.map((value) => {
            console.log(`value`)
            console.log(value)
            if (value == null) {
                return (<div></div>)
            } else {
                return (
                    <div className="card">
                        <a className="a-tag-without-link" onClick={() => { this.openImage(value.image) }}>
                            <img src={value.image.length ? value.image[0] : ''} alt="" className="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">{value.title}</h5>
                                <p className="card-text">
                                    {value.text}
                                </p>
                                <p className="card-text"><small className=""><i className="fas fa-calendar-alt"></i>{new Date(Number(value.date)).toLocaleString()}</small></p>
                            </div>
                        </a>
                    </div>
                )
            }
        })
    }
    render() {
        console.log(this.state)
        if (!this.state.isLoading) {
            return (
                <div>
                    {this.state.src.length ? <div id="myModal" className="modal-2">

                        <div className="modal-content-2">
                            <span onClick={() => { this.setState({ src: [] }) }}>X</span>
                            <Carousel
                                showStatus={false}
                                showThumbs={false}
                                autoPlay={false}
                                infiniteLoop={false}
                                showArrows={true}
                            >
                                {this.printImagesFromNews(this.state.src)}
                            </Carousel>
                        </div>

                    </div> : <div></div>}
                    <div className="row name-tournaments">
                        <div className={this.renderBootstrapColumns(12)}>
                            <div className="container">
                                <div className="container">
                                    <div className="card-columns">
                                        {this.printNews()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

export default News