import React from 'react';
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import Tournaments from './components/Tournaments';
import Prices from './components/Prices'
import News from './components/News';
import Contacts from './components/Contacts';
import AddData from './components/admin/AddData';
import Login from './components/admin/Login';
import history from './history'

class App extends React.Component {
  constructor() {
    super();
  }


  render() {

    return (
      <div className="root">
        <Navbar></Navbar>
        <Router history={history}>
          <div className="container-custom">
            <Switch>
              <Route path="/home"><Home></Home></Route>
              <Route path="/tournaments"><Tournaments></Tournaments></Route>
              {/* <Route path="/prices"><Prices></Prices></Route> */}
              <Route path="/news"><News></News></Route>
              <Route path="/contacts"><Contacts></Contacts></Route>
              <Route path="/addData"><AddData></AddData></Route>
              <Route path="/login"><Login></Login></Route>
              <Route path="/"><Home></Home></Route>
            </Switch>
          </div>
        </Router >
      </div>
    )
  }
}

export default App;
