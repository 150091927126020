import { Component } from 'react'
import Select from 'react-select'
import axios from 'axios';
import Cookies from 'js-cookie';
import Loading from '../Loading';
import { Redirect } from "react-router-dom";
import config from '../config.js'
import Modal from 'react-modal';

import uploadImageApi from '../uploadImages/uploadImage'

class AddData extends Component {
    constructor() {
        super();
        this.state = {
            isLoadingImageUpload: false,
            isLoading: false,
            isLoggedIn: true,
            tournaments: [],
            teams: [],
            tournamentSelected: null,
            teamA: null,
            teamB: null,
            teamAGoals: null,
            teamBGoals: null,
            nameOfTeam: null,
            nameOfTournament: null,
            newsTextArea: null,
            titleNews: null,
            tournamentNew: null,
            uploadedImg: [],
            tournamentsDetails: {
                visible: true
            },
            news: {
                visible: false
            },
            hours: {
                visible: false
            },
            prices: {
                visible: false
            },
            deleteTournaments: {
                visible: false
            },
            save: false,
            officialLoss: false,
            matches: [],
            modifiedResult: {
                teamA: null,
                teamB: null
            },
            newsElements: []
        }
    }
    async componentDidMount() {
        this.getInfo()
    }
    async getInfo() {
        let isLoggedIn = await axios.get(`${config.path}checkToken`, { headers: { 'authorization': Cookies.get('token') } })
            .then(res => {
                if (res.status === 200) {
                    return true
                } else {
                    return false
                }
            }).catch((err) => {
                return false
            })


        let tournaments = await axios.post(`${config.path}getAllTournaments`).then((res) => {
            let tournaments = [];
            if (res.status == 200) {
                tournaments = res.data.data.map((value) => {
                    return {
                        label: value.name,
                        value: value.id
                    }
                })
            }
            return tournaments
        })
        let newsElements = await axios.get(`${config.path}getAllNews`).then((res) => {
            return res.data.data
        })



        this.setState({ newsElements, tournaments, isLoading: false, isLoggedIn })
    }

    getMatchesFromTournament = async (tournament) => {
        this.setState({ isLoading: true })
        let matches = await axios.post(`${config.path}getMatchesFromTournament`, { tournamentId: tournament.value }).then((res) => {
            return res.data.data
        })
        this.setState({ matches, isLoading: false })
    }
    onChangeTournaments = async (tournament) => {
        console.log(`onchange`)
        let teams = [];
        await axios.post(`${config.path}getTeamsFromTournament`, { tournamentId: tournament.value }).then((res) => {
            console.log(`res`)
            res.data.data.map((value) => {
                teams.push({
                    label: value.name,
                    value: value.id
                })
            })
            this.setState({ tournamentSelected: tournament.value, teams })
        })
        console.log(`onchange2`)

    }
    renderBootstrapOffset = (cols) => {
        return `offset-lg-${cols} offset-md-${cols} offset-xs-${cols}`
    }
    onChangeResultTeamA = async (result) => {
        this.setState({ teamAGoals: result.target.value })
    }
    onChangeResultTeamB = async (result) => {
        this.setState({ teamBGoals: result.target.value })
    }
    onChangeTeamA = async (result) => {
        this.setState({ teamA: result })
    }
    onChangeTeamB = async (result) => {
        this.setState({ teamB: result })
    }
    onChangeTeamName = async (result) => {
        this.setState({ nameOfTeam: result.target.value })
    }
    onChangeTournamentName = async (result) => {
        this.setState({ nameOfTournament: result.target.value })
    }
    onChangeTournamentsNews = async (result) => {
        console.log(result)
        this.setState({ tournamentNew: result.value })
    }
    saveChanges = () => {
        let body = {
            teamAId: this.state.teamA.value,
            teamBId: this.state.teamB.value,
            result: `${this.state.teamAGoals}-${this.state.teamBGoals}`,
            tournamentId: this.state.tournamentSelected,
            isOfficialLoss: this.state.officialLoss
        }
        this.setState({ save: true })
        axios.post(`${config.path}addMatch`, body).then((res) => {
            console.log(res)
            window.location.reload(false);
        })
    }
    saveTeam = () => {
        let body = {
            name: this.state.nameOfTeam,
            tournamentId: this.state.tournamentSelected
        }
        this.setState({ save: true })
        axios.post(`${config.path}addTeam`, body).then((res) => {
            console.log(res)
            window.location.reload(false);
        })
    }
    saveTournament = () => {
        let body = {
            name: this.state.nameOfTournament,
        }
        this.setState({ save: true })
        axios.post(`${config.path}addTournament`, body).then((res) => {
            console.log(res)
            window.location.reload(false);
        })
    }
    renderBootstrapColumns = (cols) => {
        return `col-lg-${cols} col-md-${cols} col-xs-${cols}`
    }

    logout = () => {
        Cookies.set('token', '');
        this.setState({ isLoggedIn: false })
    }
    modifyMatch = async (id, result, tournament) => {
        let modifiedMatch = this.state.modifiedResult;
        if (modifiedMatch.teamA == result[0] && modifiedMatch.teamB == result[1]) {
            alert(`промените който си направил са еднакви`)
        } else if (modifiedMatch.teamA == null && modifiedMatch.teamB == null) {
            alert(`не си направил никви промени`)
        } else {
            if (modifiedMatch.teamA == null) {
                modifiedMatch.teamA = result[0];
            }
            if (modifiedMatch.teamB == null) {
                modifiedMatch.teamB = result[1];
            }
            if (window.confirm('Сигурен ли си че искаш да запазиш промените')) {
                await axios.post(`${config.path}modifyMatchResult`, { matchId: id, newResult: modifiedMatch }).then((res) => {
                    return
                })
            } else {
                // Do nothing!
                console.log('Thing was not saved to the database.');
            }
        }
        this.setState({ modifiedResult: { teamA: null, teamB: null } })

        this.getMatchesFromTournament(tournament)

    }

    changeModifyInputA = (e) => {
        let modifiedResult = this.state.modifiedResult;
        modifiedResult.teamA = e.target.value
        this.setState({ modifiedResult })
    }
    changeModifyInputB = (e) => {
        let modifiedResult = this.state.modifiedResult;
        modifiedResult.teamB = e.target.value
        this.setState({ modifiedResult })
    }

    printResults = () => {
        return this.state.matches.map((value) => {
            let result = value.result.split('-')
            if (Number(result[0]) > Number(result[1])) {
                return (
                    <tr>
                        <td className="win-color">{value.teamAName}</td>
                        <td className="result-a"><input onChange={this.changeModifyInputA} className="input-goals-modify" type="text" placeholder={result[0]} /></td>
                        <td className="img-ball-results"><img src="./ball.png"></img></td>
                        <td className="result-b"><input onChange={this.changeModifyInputB} className="input-goals-modify" type="text" placeholder={result[1]} /></td>
                        <td className="lost-color away-team">{value.teamBName}</td>
                        <td className="draw-color away-team"><button onClick={() => { this.modifyMatch(value.id, result, { value: value.tournamentId }) }} className="btn btn-success">Save</button></td>
                    </tr>
                )
            } else if (Number(result[0]) === Number(result[1])) {
                return (<tr>
                    <td className="draw-color">{value.teamAName}</td>
                    <td className="result-a"><input onChange={this.changeModifyInputA} className="input-goals-modify" type="text" placeholder={result[0]} /></td>
                    <td className="img-ball-results"><img src="./ball.png"></img></td>
                    <td className="result-b"><input onChange={this.changeModifyInputB} className="input-goals-modify" type="text" placeholder={result[1]} /></td>
                    <td className="draw-color away-team">{value.teamBName}</td>
                    <td className="draw-color away-team"><button onClick={() => { this.modifyMatch(value.id, result, { value: value.tournamentId }) }} className="btn btn-success">Save</button></td>
                </tr>)
            } else {
                return (<tr>
                    <td className="lost-color">{value.teamAName}</td>
                    <td className="result-a"><input onChange={this.changeModifyInputA} className="input-goals-modify" type="text" placeholder={result[0]} /></td>
                    <td className="img-ball-results"><img src="./ball.png"></img></td>
                    <td className="result-b"><input onChange={this.changeModifyInputB} className="input-goals-modify" type="text" placeholder={result[1]} /></td>
                    <td className="win-color away-team">{value.teamBName}</td>
                    <td className="draw-color away-team"><button onClick={() => { this.modifyMatch(value.id, result, { value: value.tournamentId }) }} className="btn btn-success">Save</button></td>
                </tr>)
            }

        })
    }

    changeBar = (tab) => {
        let obj = {
            'tournamentsDetails': {
                visible: false
            },
            'news': {
                visible: false
            },
            'hours': {
                visible: false
            },
            'prices': {
                visible: false
            },
            'deleteTournaments': {
                visible: false
            }
        }

        obj[tab] = {
            visible: true
        }

        this.setState({ tournamentsDetails: obj['tournamentsDetails'], news: obj['news'], hours: obj['hours'], prices: obj['prices'], deleteTournaments: obj['deleteTournaments'] })

    }

    onChangeNews = (val) => {
        this.setState({ newsTextArea: val.target.value })
    }

    onChangeImage = async (e) => {
        this.setState({ isLoadingImageUpload: true })
        let image = await uploadImageApi.uploadImage(e.target.files)
        let imagesToUpload = this.state.uploadedImg;
        imagesToUpload.push(image.url)
        if (image) {
            this.setState({ uploadedImg: imagesToUpload, isLoadingImageUpload: false })
        }
    }

    saveNew = () => {
        let body = {
            title: this.state.titleNews,
            text: this.state.newsTextArea,
            image: this.state.uploadedImg,
            tournamentId: this.state.tournamentNew
        }
        this.setState({ save: true })
        axios.post(`${config.path}addNews`, body).then((res) => {
            console.log(res)
            window.location.reload(false);
        })
    }

    onChangeTitle = (e) => {
        this.setState({ titleNews: e.target.value })
    }
    renderImages = (images) => {

        return images.map((value, index) => {
            console.log(value)
            return (<div className={this.renderBootstrapColumns(3)}>
                <img className="img-uploaded" src={value}></img>
            </div>)
        })
    }
    changeOfficialLoss = () => {
        this.setState({ officialLoss: !this.state.officialLoss })
    }
    removeNew = (id) => {
        if (window.confirm('Сигурен ли си че искаш да истриеш тази новина')) {
            axios.post(`${config.path}removeNewById`, { newId: id }).then((res) => {
                return
            })
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }

    }
    deleteTournament = (id) => {
        if (window.confirm('Сигурен ли си че искаш да истриеш този турнир')) {
            axios.post(`${config.path}deleteTournamentById`, { tournamentId: this.state.tournamentSelected }).then((res) => {
                this.getInfo()
            })
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }

    }

    printNews = () => {
        let news = this.state.newsElements

        return news.map((value) => {
            return (
                <div className="card">
                    <span className='remove-new' onClick={() => { this.removeNew(value.id) }}>X</span>
                    <a className="a-tag-without-link" onClick={() => { this.openImage(value.image) }}>
                        <img src={value.image[0]} alt="" className="card-img-top" />
                        <div className="card-body">
                            <h5 className="card-title">{value.title}</h5>
                            <p className="card-text">
                                {value.text}
                            </p>
                            <p className="card-text"><small className=""><i className="fas fa-calendar-alt"></i>{new Date(Number(value.date)).toLocaleString()}</small></p>
                        </div>
                    </a>
                </div>
            )
        })
    }

    render() {
        console.log(this.state.matches)
        if (!this.state.isLoading) {
            if (!this.state.isLoggedIn) {
                return (<Redirect to="/home"></Redirect>)
            } else {
                return (
                    <div className="containerAddData">
                        {this.state.save && <div id="myModal" class="modal">

                            <div class="modal-content">
                                <p>Успешно изпълнено</p>
                            </div>

                        </div>}
                        <nav className="menu-admin">
                            <button onClick={() => { this.logout() }} class="btn button-arrow"><i class="arrow left"></i>Излез</button>
                            <a onClick={() => { this.changeBar('tournamentsDetails') }} className={`links-tournaments ${this.state.tournamentsDetails.visible ? 'active' : ''}`}>Турнири</a>
                            <a onClick={() => { this.changeBar('news') }} className={`links-tournaments ${this.state.news.visible ? 'active' : ''}`}>Новини</a>
                            <a onClick={() => { this.changeBar('hours') }} className={`links-tournaments ${this.state.hours.visible ? 'active' : ''}`}>Резултати</a>
                            <a onClick={() => { this.changeBar('prices') }} className={`links-tournaments ${this.state.prices.visible ? 'active' : ''}`}>Работно Време</a>
                            <a onClick={() => { this.changeBar('deleteTournaments') }} className={`links-tournaments ${this.state.deleteTournaments.visible ? 'active' : ''}`}>Изтриване на турнири</a>
                        </nav>
                        {/* TOURNAMENTS */}
                        {
                            this.state.tournamentsDetails.visible &&
                            <div className="row admin-container">

                                {/* ADD MATCH */}
                                <div className={this.renderBootstrapColumns(12)}>
                                    <div className="row played-match-container">
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="played-match-title"><span>Резултат изигран мач</span></div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="row">
                                                <div className={this.renderBootstrapColumns(4)}>
                                                    <label className="label-teams">Турнир</label>
                                                    <Select onChange={this.onChangeTournaments} className="input-first-team" options={this.state.tournaments} />
                                                </div>
                                                <div className={this.renderBootstrapColumns(3)}>
                                                    <div className="row">
                                                        <div className={this.renderBootstrapColumns(9)}>
                                                            <div className="row">
                                                                <div className={this.renderBootstrapColumns(12)}>
                                                                    <label className="label-teams">Отбор А</label>
                                                                </div>
                                                                <div className={this.renderBootstrapColumns(12)}>
                                                                    <Select onChange={this.onChangeTeamA} className="input-first-team" options={this.state.teams} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`goals-container ` + this.renderBootstrapColumns(3)}>
                                                            <div className={this.renderBootstrapColumns(12)}>
                                                                <label className="label-teams">Голове</label>
                                                            </div>
                                                            <div className={this.renderBootstrapColumns(12)}>
                                                                <div class="form-group">
                                                                    <input onChange={this.onChangeResultTeamA} className="input-goals" type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={this.renderBootstrapColumns(1)}>
                                                    <label className="vs-label">VS</label>
                                                </div>
                                                <div className={this.renderBootstrapColumns(3)}>
                                                    <div className="row">
                                                        <div className={this.renderBootstrapColumns(9)}>
                                                            <div className="row">
                                                                <div className={this.renderBootstrapColumns(12)}>
                                                                    <label className="label-teams">Отбор Б</label>
                                                                </div>
                                                                <div className={this.renderBootstrapColumns(12)}>
                                                                    <Select onChange={this.onChangeTeamB} className="input-first-team" options={this.state.teams} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`goals-container ` + this.renderBootstrapColumns(3)}>
                                                            <div className={this.renderBootstrapColumns(12)}>
                                                                <label className="label-teams">Голове</label>
                                                            </div>
                                                            <div className={this.renderBootstrapColumns(12)}>
                                                                <div class="form-group">
                                                                    <input onChange={this.onChangeResultTeamB} className="input-goals" type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={this.renderBootstrapColumns(1)}>
                                                    <div class="form-check official-loss">
                                                        {this.state.OfficialLoss ? <input onChange={this.changeOfficialLoss} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked /> :
                                                            <input onChange={this.changeOfficialLoss} class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />}
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Служебна
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <button onClick={this.saveChanges} type="button" class="btn btn-success">Запиши</button>
                                        </div>
                                    </div>
                                </div>

                                {/* ADD TEAM */}
                                <div className={this.renderBootstrapColumns(12)}>
                                    <div className="row played-match-container">
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="played-match-title"><span>Добави отбор</span></div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="row">
                                                <div className={this.renderBootstrapColumns(6)}>
                                                    <label className="label-teams">Турнир</label>
                                                    <Select onChange={this.onChangeTournaments} className="input-first-team" options={this.state.tournaments} />
                                                </div>
                                                <div className={this.renderBootstrapColumns(6)}>
                                                    <label className="label-teams">Име на отбора</label>
                                                    <input onChange={this.onChangeTeamName} className="input-goals" type="text" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <button onClick={this.saveTeam} type="button" class="btn btn-success">Запиши</button>
                                        </div>
                                    </div>
                                </div>
                                {/* ADD TOURNAMENT */}
                                <div className={this.renderBootstrapColumns(12)}>
                                    <div className="row played-match-container">
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="played-match-title"><span>Добави Турнир</span></div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="row">
                                                <div className={this.renderBootstrapColumns(12)}>
                                                    <label className="label-teams">Име на Турнира</label>
                                                    <input onChange={this.onChangeTournamentName} className="input-goals" type="text" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <button onClick={this.saveTournament} type="button" class="btn btn-success">Запиши</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* NEWS */}
                        {
                            this.state.news.visible &&
                            <div className="row admin-container">
                                <div className={this.renderBootstrapColumns(12)}>
                                    <div className="row played-match-container">
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="played-match-title"><span>Добави новина</span></div>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <label className="label-teams">Заглавие</label>
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <input onChange={this.onChangeTitle} className="input-goals" type="text" class="form-control" />
                                        </div>
                                        <div className={this.renderBootstrapColumns(9)}>
                                            <label className="label-teams">Описание</label>
                                        </div>
                                        <div className={this.renderBootstrapColumns(9)}>
                                            <textarea onChange={this.onChangeNews} class="form-control rounded-0" id="exampleFormControlTextarea2" rows="3"></textarea>
                                        </div>
                                        <div className={this.renderBootstrapColumns(3)}>
                                            <label className="label-teams">Турнир</label>
                                            <Select onChange={this.onChangeTournamentsNews} className="input-first-team" options={this.state.tournaments} />
                                        </div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            {this.state.isLoadingImageUpload ? <Loading isImage={true}></Loading> : this.state.uploadedImg.length ?

                                                <div className="row">
                                                    {this.renderImages(this.state.uploadedImg)}
                                                </div>
                                                : <span className="not-found-pic">Няма качена снимка</span>}
                                        </div>
                                        <div className={this.renderBootstrapColumns(9)}>
                                            <button onClick={this.saveNew} type="button" class="btn btn-success">Запиши</button>
                                        </div>
                                        <div className={this.renderBootstrapColumns(3)}>
                                            <input onChange={this.onChangeImage} type="file" id="upload" hidden />
                                            <label className="btn btn-success" for="upload">Качи снимка</label>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.renderBootstrapColumns(12)}>
                                    <div className="row name-tournaments">
                                        <div className={this.renderBootstrapColumns(12)}><span>Новини</span></div>
                                        <div className={this.renderBootstrapColumns(12)}>
                                            <div className="container">
                                                <div className="container">
                                                    <div className="card-columns">
                                                        {this.printNews()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* HOURS */}
                        {
                            this.state.hours.visible &&
                            <div className="row tournaments-container">
                                <div className={this.renderBootstrapColumns(12)}><span>Резултати</span></div>
                                <div className={this.renderBootstrapColumns(12)}><span></span>
                                    <div className={`${this.renderBootstrapOffset(3)} ${this.renderBootstrapColumns(6)}`}>
                                        <label className="label-teams">Турнир</label>
                                        <Select onChange={this.getMatchesFromTournament} className="input-first-team" options={this.state.tournaments} />
                                    </div>
                                </div>
                                <div className={`${this.renderBootstrapOffset(3)} ${this.renderBootstrapColumns(6)}`}>
                                    <div class="tbl-header">
                                        <table cellpadding="0" cellspacing="0" border="0">
                                            <thead>
                                                <tr>
                                                    <th> </th>
                                                    <th> </th>
                                                    <th> </th>
                                                    <th> </th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="tbl-content">
                                        <table cellpadding="0" cellspacing="0" border="0">
                                            <tbody>
                                                {this.printResults()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* DELETE TOURNAMENTS */}
                        {
                            this.state.deleteTournaments.visible &&
                            <div className="row tournaments-container">
                                <div className={this.renderBootstrapColumns(12)}>
                                    <label className="label-teams">Турнир</label>
                                    <Select onChange={this.onChangeTournaments} className="input-first-team" options={this.state.tournaments} />
                                </div>
                                <div className={this.renderBootstrapColumns(12)}>
                                    <button onClick={this.deleteTournament} type="button" class="btn btn-danger">Изтриване</button>
                                </div>

                            </div>

                        }
                    </div>
                )
            }

        } else {
            return (
                <Loading></Loading>
            )
        }
    }
}

export default AddData