import { Component } from 'react'
import Loader from 'react-loader-spinner'
import { isMobile } from 'mobile-device-detect';
class Loading extends Component {
    render() {
        return (<div className="loader">
            <Loader
                className="loader-isnside"
                type="Circles"
                color="rgb(0, 163, 224)"
                height={isMobile || this.props.isImage ? 250 : 500}
                width={isMobile || this.props.isImage ? 250 : 500}
                timeout={3000} //3 secs
            />
        </div>)
    }
}

export default Loading