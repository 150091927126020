import { Component } from 'react'

class Prices extends Component {

    renderBootstrapColumns = (cols) => {
        return `col-lg-${cols} col-md-${cols} col-xs-${cols}`
    }
    renderBootstrapOffset = (cols) => {
        return `offset-lg-${cols} offset-md-${cols} offset-xs-${cols}`
    }
    render() {
        return (

            <div className="row name-tournaments">
                <div className={this.renderBootstrapColumns(12)}><span className='title-home' >Цени</span></div>
                <div className={this.renderBootstrapColumns(12)}>
                    <div class="pricing-table">
                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2 className="title-futbol">Футбол</h2>
                                        <h2>от 18:00 до 23:00 всеки ден</h2>
                                    </div>

                                    <div class="ptable-price">
                                        <h2><small></small>60лв<span>/ час</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2 className="title-futbol">Футбол</h2>
                                        <h2>от 08:00 до 17:00 всеки ден</h2>
                                    </div>

                                    <div class="ptable-price">
                                        <h2><small></small>40лв<span>/ час</span></h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2 className="title-futbol">Тенис</h2>
                                        <h2 className="title-teren">1,2,3 корт</h2>
                                        <h2>Понеделник - Петък</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small></small>10лв<span>/ час | 08:00-17:00</span></h2>
                                        <h2><small></small>12лв<span>/ час | 17:00-22:00</span></h2>
                                    </div>
                                    <hr></hr>
                                    <div class="ptable-title">
                                        <h2>Събота и Неделя</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small></small>12лв<span>/ час</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ptable-item">
                            <div class="ptable-single">
                                <div class="ptable-header">
                                    <div class="ptable-title">
                                        <h2 className="title-futbol">Тенис</h2>
                                        <h2 className="title-teren">4 и 5 корт</h2>
                                        <h2>Понеделник - Петък</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small></small>12лв<span>/ час | 08:00-17:00</span></h2>
                                        <h2><small></small>14лв<span>/ час | 17:00-22:00</span></h2>
                                    </div>
                                    <hr></hr>
                                    <div class="ptable-title">
                                        <h2>Събота и Неделя</h2>
                                    </div>
                                    <div class="ptable-price">
                                        <h2><small></small>14лв<span>/ час</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        )
    }
}

export default Prices